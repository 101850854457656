<template>
  <div>
    <div class="box">
      <div class="receiving_people" @click="toAddress()">
        <div class="icom_box">
          <img src="../../assets/logistics/icon.png" alt="" />
        </div>
        <div class="peoprl_details" v-if="addressInfo">
          <p class="receiving_people_name">
           {{ addressInfo.contactName }}
            <span>{{ addressInfo.mobile }}</span>
          </p>
          <p class="receiving_people_address">
            收货地址：{{ addressInfo.addressDetail }}
          </p>
        </div>
        <div class="peoprl_details" v-else>
          <p class="receiving_people_name">请选择地址</p>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    addressInfo: {
      type: Object,
    },
  },
  methods: {
    toAddress() {
      console.log("in");
      this.$router.push("/receiveAddress");
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  background-color: #12172c;
  margin-top: 108px;
}
.receiving_people {
  display: flex;
  align-items: center;
  padding: 51px 30px;
  position: relative;
  .icom_box {
    width: 50px;
    height: 50px;
  }
  .peoprl_details {
    width: 620px;
    margin-left: 23px;
    .receiving_people_name {
      color: #00ccff;
      font-size: 28px;
      span {
        margin-left: 30px;
      }
    }
    .receiving_people_address {
      font-size: 26px;
      color: #7f89b3;
      margin-top: 20px;
    }
  }
}
.line {
  width: 100%;
  height: 6px;
  background-image: url("../../assets/logistics/bg_line.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
